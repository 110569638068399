













































import Race from '@/models/race';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeleteRace extends Vue {
  @Prop({ required: true }) readonly race!: Race;
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly seriesId!: string;

  dialog = false;
  loading = false;

  async confirmDelete() {
    this.loading = true;
    await this.$store.dispatch('races/deleteRace', {
      orgId: this.orgId,
      seriesId: this.seriesId,
      raceId: this.race.id,
    });
    await this.$store.dispatch('races/getAllRacesList', { orgId: this.orgId });
    this.dialog = false;
    this.loading = false;
  }
}
