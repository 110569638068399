







































import { Component, Prop, Vue } from 'vue-property-decorator';
import LeaderboardService from '@/api/leaderboard';
import { unparse } from 'papaparse';
import { RaceScoringMethod } from '@/models';
import { Duration } from 'luxon';

@Component
export default class DownloadLeaderboard extends Vue {
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly seriesId!: string;
  @Prop({ required: true }) readonly raceScoringMethod!: RaceScoringMethod;

  loading = false;

  async exportLeaderboard() {
    this.loading = true;
    try {
      const allLeaderboards = await LeaderboardService.getLeaderboards(this.orgId, this.seriesId);

      if (!allLeaderboards?.length) {
        throw new Error('No leaderboard data found');
      }

      const dynColNameSet = new Set<string>();
      const flattenedLeaderboards = allLeaderboards.map(l => {
        const flb: any = { ...l };
        l.races.forEach(race => {
          if (this.raceScoringMethod === 'Age Graded') {
            flb[`${race.raceName} - score`] = race.score;
            dynColNameSet.add(`${race.raceName} - score`);
            flb['total'] = l.score;
          } else {
            flb[`${race.raceName} - time`] = race.time;
            dynColNameSet.add(`${race.raceName} - time`);
            flb['total'] = Duration.fromMillis(l.score * 1000).toFormat('hh:mm:ss');
          }
        });

        return flb;
      });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(
        new Blob(
          [
            unparse(flattenedLeaderboards, {
              columns: [
                'id',
                'firstName',
                'lastName',
                'club',
                'division',
                'ageDivision',
                'rank',
                'total',
                ...dynColNameSet,
              ],
            }),
          ],
          { type: 'text/csv' }
        )
      );
      link.download = `${this.seriesId}-${new Date().toISOString().substr(0, 10)}`;
      link.click();
      URL.revokeObjectURL(link.href);
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$store.dispatch(
        'errorModal/showDialog',
        {
          title: 'Error exporting leaderboard',
          message: `Failed to export leaderboard for series ${this.seriesId}: ` + e,
        },
        { root: true }
      );
    }
  }
}
