






























































































































import { LeaderboardScoringMethod, RaceScoringMethod } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component
export default class EditSeries extends Vue {
  @Prop({ required: true }) readonly seriesName!: string;
  @Prop({ required: true }) readonly seriesId!: string;
  @Prop({ required: true }) readonly ageReferenceDate!: string;
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly leaderboardScoringMethod!: LeaderboardScoringMethod;
  @Prop({ required: true }) readonly raceScoringMethod!: RaceScoringMethod;
  @Prop({ required: true }) readonly topN!: number | undefined;

  showSave = false;
  newSeriesName = this.seriesName;
  newAgeReferenceDate = this.ageReferenceDate;
  dialog = false;
  ageReferenceDateMenu = false;
  loading = false;

  newLeaderboardScoringMethod: LeaderboardScoringMethod = this.leaderboardScoringMethod;
  newRaceScoringMethod: RaceScoringMethod = this.raceScoringMethod;
  topNText = this.topN?.toString() ?? '';

  leaderboardScoringMethods: LeaderboardScoringMethod[] = ['Top N', 'Full Series'];
  raceScoringMethods: RaceScoringMethod[] = ['Age Graded', 'Minimum Total Time'];

  async save() {
    this.loading = true;
    await this.$store.dispatch('series/updateSeries', {
      orgId: this.orgId,
      series: {
        id: this.seriesId,
        name: this.newSeriesName,
        ageReferenceDate: this.newAgeReferenceDate,
        leaderboardScoringMethod: this.newLeaderboardScoringMethod,
        raceScoringMethod: this.newRaceScoringMethod,
        topN: parseInt(this.topNText, 10),
      },
    });
    await this.$store.dispatch('series/getAllSeriesList');
    this.dialog = false;
    this.loading = false;
  }

  seriesNameValid(name: string): boolean {
    const valid = name !== null && name !== undefined && name.length !== 0;
    this.showSave = valid;
    return valid;
  }

  seriesDateValid(date: string): boolean {
    const valid = date !== null && date !== undefined && DateTime.fromISO(date).isValid;
    this.showSave = valid;
    return valid;
  }
}
