




















































import { Organization } from '@/models';
import { Component, Vue } from 'vue-property-decorator';
import SeriesList from '@/components/series/SeriesList.vue';
import NewSeries from '@/components/series/NewSeries.vue';

@Component({
  components: {
    SeriesList,
    NewSeries,
  },
})
export default class Organizations extends Vue {
  get organizations(): Organization[] | null {
    return this.$store.state.organizations.organizations;
  }

  async created() {
    await this.$store.dispatch('organizations/getOrganizationsList');
    await this.$store.dispatch('series/getAllSeriesList');
  }
}
