
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Race from '@/models/race';
import NewRace from '@/components/race/NewRace.vue';
import EditRace from '@/components/race/EditRace.vue';
import DeleteRace from '@/components/race/DeleteRace.vue';
import { Organization, Series } from '@/models';

@Component({
  components: {
    NewRace,
    EditRace,
    DeleteRace,
  },
})
export default class RaceList extends Vue {
  raceResultsFile: File | null = null;
  uploading = false;
  loadingRaces = false;

  @Prop({ required: true }) readonly series!: Series;
  @Prop({ required: true }) readonly org!: Organization;

  get raceList(): Race[] | null {
    return (
      this.$store.getters['races/getRaceListBasedOnSeriesId'](this.series.id, this.org.id) || []
    );
  }

  getRace(raceId: string): Race {
    return this.$store.state.races.raceList.filter((race: Race) => race.id === raceId);
  }

  async created() {
    try {
      this.loadingRaces = true;
      await this.$store.dispatch('races/getAllRacesList', { orgId: this.org.id });
    } catch (error) {
      this.$store.dispatch('errorModal/showDialog', {
        title: 'Error loading Races',
        message: `Failed to load races: ${error}`,
      });
    } finally {
      this.loadingRaces = false;
    }
  }
}
