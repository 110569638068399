







































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import NewSeries from '@/components/series/NewSeries.vue';
import EditSeries from '@/components/series/EditSeries.vue';
import DeleteSeries from '@/components/series/DeleteSeries.vue';
import RaceList from '@/components/race/RaceList.vue';
import DownloadLeaderboard from '@/components/DownloadLeaderboard.vue';
import { Series, Organization } from '@/models';

@Component({
  components: {
    NewSeries,
    EditSeries,
    DeleteSeries,
    RaceList,
    DownloadLeaderboard,
  },
})
export default class SeriesList extends Vue {
  @Prop({ required: true }) readonly org!: Organization;

  panel?: number;

  get seriesList(): Series[] | null {
    return this.$store.getters['series/getSeriesBasedOnOrgId'](this.org.id);
  }

  async created() {
    await this.$store.dispatch('series/getAllSeriesList');
    const panel = this.$route.query.panel;
    if (panel) {
      const idx = this.seriesList?.findIndex(s => panel === `${this.org.id}-${s.id}$`);
      if (idx !== undefined) {
        this.panel = idx;
      }
    }
  }

  async setParam(key: string) {
    await this.$router.replace({
      query: { panel: key },
    });
  }
}
