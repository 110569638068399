




















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeleteSeries extends Vue {
  @Prop({ required: true }) readonly seriesName!: string;
  @Prop({ required: true }) readonly seriesId!: string;
  @Prop({ required: true }) readonly orgId!: string;

  dialog = false;
  loading = false;

  async confirmDelete() {
    this.loading = true;
    await this.$store.dispatch('series/deleteSeries', {
      orgId: this.orgId,
      seriesId: this.seriesId,
    });
    await this.$store.dispatch('series/getAllSeriesList');
    this.dialog = false;
    this.loading = false;
  }
}
