
































































































































import { LeaderboardScoringMethod, Organization, RaceScoringMethod } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component
export default class NewSeries extends Vue {
  @Prop({ default: 'Go!' }) readonly buttonContent!: string;
  @Prop({ required: true }) readonly org!: Organization;

  seriesName = '';
  showSave = false;
  dialog = false;
  saving = false;
  ageReferenceDate = new Date().toISOString().substr(0, 10);
  ageReferenceDateMenu = false;

  leaderboardScoringMethod: LeaderboardScoringMethod =
    this.org.defaultLeaderboardScoringMethod ?? 'Top N';
  raceScoringMethod: RaceScoringMethod = this.org.defaultRaceScoringMethod ?? 'Age Graded';
  topNText: string = this.org.defaultTopN ? `${this.org.defaultTopN}` : '3';

  leaderboardScoringMethods: LeaderboardScoringMethod[] = ['Top N', 'Full Series'];
  raceScoringMethods: RaceScoringMethod[] = ['Age Graded', 'Minimum Total Time'];

  get errorDialog() {
    return this.$store.getters['errorModal/getDialogStatus'];
  }

  async save() {
    try {
      this.saving = true;
      await this.$store.dispatch('series/addSeries', {
        orgId: this.org.id,
        series: {
          name: this.seriesName,
          ageReferenceDate: this.ageReferenceDate,
          leaderboardScoringMethod: this.leaderboardScoringMethod,
          raceScoringMethod: this.raceScoringMethod,
          topN: parseInt(this.topNText, 10),
        },
      });
    } catch (error) {
      this.saving = false;
      this.dialog = false;
    } finally {
      await this.$store.dispatch('series/getAllSeriesList');
      this.saving = false;
      this.dialog = false;
    }
  }

  seriesNameValid(name: string): boolean {
    const valid = name !== null && name !== undefined && name.length !== 0;
    this.showSave = valid;
    return valid;
  }

  seriesDateValid(date: string): boolean {
    const valid = date !== null && date !== undefined && DateTime.fromISO(date).isValid;
    this.showSave = valid;
    return valid;
  }
}
