






































































































import Race from '@/models/race';
import { RaceType } from '@/models/raceType';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import slugify from 'slugify';

@Component
export default class NewRace extends Vue {
  @Prop({ default: 'Go!' }) readonly buttonContent!: string;
  @Prop({ required: true }) readonly orgId!: string;
  @Prop({ required: true }) readonly seriesId!: string;
  @Prop({ required: true }) readonly orgSlug!: string;

  raceName = '';
  showSave = false;
  raceType = '';
  raceTypes: { text: string; value: string }[] = [];
  raceDate = new Date().toISOString().substr(0, 10);
  raceDateMenu = false;
  dialog = false;
  saving = false;

  get errorDialog() {
    return this.$store.getters['errorModal/getDialogStatus'];
  }

  async created() {
    if (this.$store.state.raceTypes.raceTypes == null) {
      await this.$store.dispatch('raceTypes/getRaceTypes');
    }
    this.raceTypes = this.$store.state.raceTypes.raceTypes.map((raceType: RaceType) => ({
      text: raceType.name,
      value: raceType.id,
    }));

    this.raceType = this.raceTypes[0].value;
  }

  async save() {
    const newRace: Partial<Race> = {
      name: this.raceName,
      raceType: this.$store.state.raceTypes.raceTypes.find(
        (rt: RaceType) => rt.id === this.raceType
      ),
      date: this.raceDate,
    };

    try {
      this.saving = true;
      await this.$store.dispatch('races/addRace', {
        orgId: this.orgId,
        seriesId: this.seriesId,
        race: newRace,
      });
    } catch (error) {
      this.saving = false;
      this.dialog = false;
    } finally {
      const slug = slugify(this.raceName, { lower: true, strict: true });
      await this.$store.dispatch('races/getAllRacesList', { orgId: this.orgId });
      this.$router.push(`organizations/${this.orgSlug}/series/${this.seriesId}/races/${slug}`);
      this.saving = false;
      this.dialog = false;
    }
  }

  raceNameValid(name: string): boolean {
    const valid = name !== null && name !== undefined && name.length !== 0;
    this.showSave = valid;
    return valid;
  }

  raceDateValid(date: string): boolean {
    const valid = date !== null && date !== undefined && DateTime.fromISO(date).isValid;
    this.showSave = valid;
    return valid;
  }
}
